window.VWFS = window.VWFS || {};
VWFS.Expansion = function (component) {


    /**
     * INIT
     */
    this.init = function () {
        component.querySelectorAll(".js-expansion-button").forEach(function (button) {
            button.addEventListener('bronson_panelchanged', clickExpansion);
        })
    }

    function clickExpansion() {
        var compareAndSelectList = component.querySelectorAll(".js-compare-and-select");
        setTimeout((function () {
            compareAndSelectList.forEach(function (element) {
                bronson.$$(element).component('compare-and-select').items[0].resize();
            })
        }), 100)
    }
};


/**
 * ON DOCUMENT LOADED
 */
document.addEventListener('DOMContentLoaded', function () {
    console.debug("INFO expansion.js loaded");

    // ITERATE THROUGH ALL EXPANSIONS-COMPONENTS AND INIT THEM
    document.querySelectorAll(".js-expansion").forEach(
        function (component) {
            new VWFS.Expansion(component).init();
        }
    );

});
